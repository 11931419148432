import React from 'react'
import { Routes, Route, Navigate } from "react-router-dom";


import Mainpage from './views/mainpage'


const App = () => (
    <Routes>
        <Route path="/exp" element={<Mainpage />}></Route>
    </Routes>
)

export default App
