import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { experienceget } from "../Redux/actions/experience";
import AudioPlayer from 'react-h5-audio-player';
import { experienceTextureGet } from '../api';

import { AFrameScene } from '../lib/aframe-components'
import { targetVideoComponent } from '../AframeComponents/targetVideoComponent'
import { chromaKeyShader } from '../AframeComponents/chromaKeyShader'
import { configTargetsComponent } from '../AframeComponents/configTargetsComponent'
import { tapHotspotComponent } from '../AframeComponents/tapHotspotComponent'
import { avatarMoveComponent, swapCamComponent, avatarRecenterComponent } from '../AframeComponents/components'
import { responsiveImmersiveComponent } from '../AframeComponents/responsive-immersive'
import { receiveMessage } from '../AframeComponents/avatar/avatar-instantiate'
import { avatarFaceComponent } from '../AframeComponents/avatar/avatar-face-effects'
const registerComponents = components => Object.keys(components).map(k => AFRAME.registerComponent(k, components[k]))
registerComponents(avatarFaceComponent())
import { animationRigComponent } from '../AframeComponents/avatar/rig-animation.js'

import '../AframeComponents/index.css'

import html from './avatar1.html'
import avatar1 from './avatar1.html'
import avatar2 from './avatar2.html'
import avatar3 from './avatar3.html'
import avatar4 from './avatar4.html'
import rpm from './rpm.html'
//import html from './rpm.html'
//  { name: 'config-targets', val: configTargetsComponent },

//window.addEventListener('message', receiveMessage, false)

const configTargetsComponents = [

  /*{ name: 'target-video', val: targetVideoComponent },*/
  { name: 'config-targets', val: configTargetsComponent },
  { name: 'tap-close', val: tapHotspotComponent },

]
const configTargetsComponentsRPM = [

  { name: 'avatar-move', val: avatarMoveComponent },
  { name: 'avatar-recenter', val: avatarRecenterComponent },
  { name: 'swap-camera', val: swapCamComponent() },
  { name: 'responsive-immersive', val: responsiveImmersiveComponent },
  { name: 'rig-animation', val: animationRigComponent }
]

//{ name: 'chromakey', val: chromaKeyShader }ч
const Scene = () => {
  //document.getElementById("video").play()
  // Store the currently selected color as react state.
  const dispatch = useDispatch();

  const [texture, setTexture] = React.useState(null);
  const [avatar, setAvatar] = React.useState(null);
  const [RPM_url, setRPM_url] = React.useState(null);
  const [voice, setVoice] = React.useState(null);

  const experienceState = useSelector((state) => state.experienceState);

  const searchParams = new URLSearchParams(document.location.search)

  React.useEffect(() => {
    console.log(searchParams.get('id'))
    dispatch(experienceget({ id: searchParams.get('id') }));

  }, [])

  React.useEffect(() => {

    experienceState.experienceResponseData?.result.RPM_url ? (
      setRPM_url(experienceState.experienceResponseData?.result.RPM_url), console.log(experienceState.experienceResponseData?.result.RPM_url)
    ) : null

    if (experienceState.experienceResponseData?.result.idVoice) {
      setVoice(`https://s3.eu-west-2.amazonaws.com/brandbuddyar/${experienceState.experienceResponseData?.result.idVoice}.mp3`)

    }
    /*  
  console.log(experienceState.experienceResponseData?.result.experiencePhoto ?
    experienceTextureGet(experienceState.experienceResponseData?.result.experiencePhoto + "") : null)
    */
    // handleFileUpload(`https://${process.env.NODE_ENV === "development" ? "localhost" : "brandbuddyar.com"}:8080/experiences/image/` + experienceState.experienceResponseData?.result.experiencePhoto)
  }, [experienceState.experienceResponseData?.result.RPM_url])


  React.useEffect(() => {
    setTimeout(() => {
      //var mesh = document.getElementById('can').getObject3D('mesh').getObjectByName('faceShape');

      //const texture1 = new window.THREE.TextureLoader().load(`https://${process.env.NODE_ENV === "development" ? "localhost" : "brandbuddyar.com"}:8080/experiences/image/${texture}`);

      //mesh.material.map = texture1

    }, 5000);


  }, [texture]);

  return (<>
    {
    /*voice ?
    <div style={{position: "absolute", zIndex: 200, width: 500}}>
      <AudioPlayer
        autoPlay
        src={voice}
        onPlay={e => console.log("onPlay")}
      // other props here
      /> 
      </div>: null
    */}
    {RPM_url ? <AFrameScene sceneHtml={[avatar1, avatar2, avatar3, avatar4, rpm]} components={configTargetsComponentsRPM} texture={texture} avatarCode={avatar} voiceURL={voice} RPM_url={RPM_url} /> : null}
  </>
  )
}

export { Scene }
