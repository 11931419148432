import React from "react";

import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import {
  menuRightOpen,
  menuRightClose,
} from "../Redux/actions";



function RightButton(props) {
  function handler() {
    props.menuRightOpen()
  }
  return (
    <div className={`right-button ${props.visible ? "" : "active"}`} >
      <div className="right-button-content">
        <a onClick={handler}>Menu</a>
      </div>
    </div>
  );

}

const mapStateToProps = (state) => {
  return {
    menuRightClose,
    menuRightOpen,
    visible: state.settingsRight,
  }
};
export default withTranslation()(
  connect(mapStateToProps)(RightButton)
);
