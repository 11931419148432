const hotSpot1Reducer = (isLoaded = false, action) => {
  switch (action.type) {
    case "HOTSPOT1_ON":
      console.log("HOTSPOT1_ON");
      return true;
    case "HOTSPOT1_OFF":
      console.log("HOTSPOT1_OFF");
      return false;
    default:
      return isLoaded;
  }
};
export default hotSpot1Reducer;
