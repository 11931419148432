const outroReducer = (isLoaded = false, action) => {
  switch (action.type) {
    case "OUTRO":
      console.log("OUTRO");
      return true;

    case "OUTRO_OFF":
      console.log("OUTRO_OFF");
      return false;
    default:
      return isLoaded;
  }
};
export default outroReducer;
