import { useEffect } from "react";
import { useSwipeable } from "react-swipeable";
import {
  menuRightClose,
  menuRightOpen,
} from "../Redux/actions";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";

function SwipeComponent(props) {
  //const [greatingOn, setGreatingOn] = useState(false);
  //let greetingAudio = new Audio(props.t("Greeting"));
  const { ref } = useSwipeable({
    onSwiped: (eventData) => {return;console.log("swiped");},
    onSwipedLeft: (eventData) => {
      console.log("swiped left");
      //if (!props.visibleRightMenu&&props.visibleLeftMenu) props.menuLeftClose();
      if (!props.visibleRightMenu) props.menuRightOpen(); //props.menuLeftClose();
    },
    onSwipedRight: (eventData) => {
      console.log("swiped right");
      if (props.visibleRightMenu) props.menuRightClose();
      //if (!props.visibleRightMenu&&!props.visibleLeftMenu) props.menuLeftOpen(); //props.menuLeftOpen();
    },
    onSwipedUp: (eventData) => {
      console.log("swiped up");
      props.menuLeftOpen();
    },
    onSwipedDown: (eventData) => {
      console.log("swiped down");
      props.menuLeftClose();
    },
    onSwiping: (eventData) => {return; console.log("swiping");},
    onTap: (eventData) => {
      console.log("onTap");
      //if (props.visibleRightMenu) props.menuRightClose();
      //setGreatingOn(true);
      //if (!greatingOn) greetingAudio.play();
    },
  });
  useEffect(() => {
    ref(document);
  });
/*
  useEffect(() => {
    console.log(greatingOn);
  }, [greatingOn]);
*/
  return null;
}
const mapStateToProps = (state) => {
  return {
    visibleLeftMenu: state.settingsLeft,
    visibleRightMenu: state.settingsRight,
    shareVisible: state.share,
    screen: state.screen,
    shotMode: state.shotMode,
  };
};

const mapDispatchToProps = () => {
  return {
    menuRightOpen,
    menuRightClose,
  };
};

export default withTranslation()(
  connect(mapStateToProps, mapDispatchToProps())(SwipeComponent)
);
