const menuUrlReducer = (isOpen = false, action) => {
  switch (action.type) {
    case "VOICE_URL_REQUESTED":
      console.log("VOICE_URL_REQUESTED");
      return false;
    case "MENU_URL_OPEN":
      isOpen = true;
      const url = action.payload;
      console.log("MENU_URL_OPEN");
      console.log(action)
      return { ...isOpen, url };
    default:
      return isOpen;
  }
};
export default menuUrlReducer;
