const tapToPlaceReducer = (isLoaded = false, action) => {
  switch (action.type) {
    case "TAP_TO_PLACE_OFF":
      console.log("TAP_TO_PLACE_OFF");
      return true;

    case "TAP_TO_PLACE_ON":
      console.log("TAP_TO_PLACE_ON");
      return false;
    default:
      return isLoaded;
  }
};
export default tapToPlaceReducer;
