import * as actionType from "../constants/actionTypes";

const changecolorReducer = (state = { color: null }, action) => {
  switch (action.type) {
    case actionType.CHANGE_COLOR:
      console.log(action.data);
      return { ...state, color: action.data };
    default:
      return state;
  }
};

export default changecolorReducer;
