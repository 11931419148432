const letsGoReducer = (isLoaded = false, action) => {
    switch (action.type) {
        case 'LETS_GO':
            console.log('LETS_GO');
            return true;
        default:
            return isLoaded;
    }

}
export default letsGoReducer;