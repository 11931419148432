const runARModeReducer = (isOpen = false, action) => {
  switch (action.type) {
    case "RUN_AR_MODE":
      console.log("RUN_AR_MODE");
      return true;
    case "RUN_AR_MODE_OFF":
      console.log("RUN_AR_MODE_OFF");
      return false;
    default:
      return isOpen;
  }
};
export default runARModeReducer;


