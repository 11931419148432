const greetingAudioReducer = (isPlay = true, action) => {
    switch (action.type) {
        case 'GREETING_AUDIO_OFF':
            console.log('GREETING_AUDIO_OFF');
            return false;
        case 'GREETING_AUDIO_ON':
            console.log('GREETING_AUDIO_ON');
            return true;
        default:
            return isPlay;
    }

}
export default greetingAudioReducer;