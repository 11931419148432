import { EXPERIENCE_PUT, EXPERIENCE_GET } from "../constants/actionTypes";
import * as api from "../../api/index.js";



export const experienceget = (formData, router) => async (dispatch) => {
  try {
    console.log('!!!!')
    const { data } = await api.experienceGet(formData);
    console.log(data)
    dispatch({ type: EXPERIENCE_GET, data });

  } catch (error) {
    console.log(error);
  }
};
