// A React component for 8th Wall AFrame scenes. The scene HTML can be supplied, along with
// any components or primitives that should be registered, and any image targets that should be
// loaded if something other than the automatically loaded set is wanted. Passing
// DISABLE_IMAGE_TARGETS will prevent any image targets from loading, including ones that would
// otherwise enabled automatically.
// Helper function to make sure that aframe components are only registered once, since they can't
// be cleanly unregistered.
import { useEffect } from 'react'
import { Howl, Howler } from 'howler';
import store from "../store";
const registeredComponents = new Set()
const registerComponents = components => components.forEach(({ name, val }) => {
  if (registeredComponents.has(name)) {
    return
  }
  registeredComponents.add(name)
  window.AFRAME.registerComponent(name, val)
})
// Helper function to make sure that aframe systems are only registered once, since they can't
// be cleanly unregistered.
const registeredSystems = new Set()
const registerSystems = systems => systems.forEach(({ name, val }) => {
  if (registeredSystems.has(name)) {
    return
  }
  registeredSystems.add(name)
  window.AFRAME.registerSystem(name, val)
})
// Helper function to make sure that aframe primitives are only registered once, since they can't
// be cleanly unregistered.
const registeredPrimitives = new Set()
const registerPrimitives = primitives => primitives.forEach(({ name, val }) => {
  if (registeredPrimitives.has(name)) {
    return
  }
  registeredPrimitives.add(name)
  window.AFRAME.registerPrimitive(name, val)
})

// A react component for loading and unloading an aframe scene. The initial scene contents should
// be specified as an html string in sceneHtml. All props must be specified when the component
// mounts. Updates to props will be ignored.
//
// Optionally, aframe coponents to register for this scene can be passed as [{name, val}] arrays.
// Care is needed here to not define the same component different across scenes, since aframe
// components can't be unloaded.
//
// Optionally imageTargets can be specified to override the set loaded by default.
function AFrameScene({ sceneHtml, imageTargets, components, systems, primitives, texture, avatarCode, voiceURL, RPM_url }) {
  useEffect(() => {
    if (imageTargets) {
      window.XR8.XrController.configure({ imageTargets })
    }
    if (components) {
      registerComponents(components)
    }
    if (systems) {
      registerSystems(systems)
    }
    if (primitives) {
      registerPrimitives(primitives)
    }
    //console.log(texture)

    //console.log(avatarCode)
    let currentAvatar;
    switch (avatarCode) {
      case 'RPM':
        currentAvatar = 0
        break;
      case 'Avatar2':
        currentAvatar = 1
        break;
      case 'Avatar3':
        currentAvatar = 2
        break;
      case 'Avatar4':
        currentAvatar = 3
        break;
      default:
        break;
    }
    //console.log(currentAvatar)
    const html = document.getElementsByTagName('html')[0]
    const origHtmlClass = html.className
    //const texture1 = new window.THREE.TextureLoader().load('https://brandbuddyar.com:8080/experiences/image/' + texture);
    sceneHtml[0] = `
    <a-scene id="ascene" xrextras-tap-recenter xrextras-loading xrextras-runtime-error xrextras-gesture-detector
    xrweb="allowedDevices: any;" vr-mode-ui="enabled: false">
  
    <a-assets>
  
      <a-asset-item id="animated-m-glb" src="./models/M_Standing_Expressions_001.glb"></a-asset-item>

      <a-asset-item id="animated-m-glb2" src="./models/M_Talking_Variations_002.glb"></a-asset-item>
      <a-asset-item id='canModel' src=${RPM_url} response-type='arraybuffer' crossorigin='anonymous'></a-asset-item>
    
    </a-assets>
  
    <a-camera id="camera" position="0 2 4" raycaster="objects: .cantap"
      cursor="fuse: false; rayOrigin: mouse;"></a-camera>
  
    <a-entity id="animated-m" gltf-model="#animated-m-glb"></a-entity>
    <a-entity id="animated-m2" gltf-model="#animated-m-glb2"></a-entity>
  
    <a-entity id='can' gltf-model='#canModel' position='0 0 -2' scale='2 2 2' visible='true'
      rig-animation="remoteId: animated-m; clip: M_Standing_Expressions_001; loop: once; crossFadeDuration: 0.4;
      remoteId2: animated-m2; clip2: M_Talking_Variations_002;">
    </a-entity>
  
  </a-scene>
  `

    document.body.insertAdjacentHTML('beforeend', sceneHtml[0])
    store.subscribe(function () {
      if (store.getState().ARMode) {
        var sound = new Howl({
          src: [voiceURL],
          volume: 1.0,
          onend: function () {
            console.log('Finished!');
            store.dispatch({ type: "RUN_AR_MODE_OFF" });
          }
        });
        setTimeout(() => {
          sound.play();
        }, 200);

 
      }
    })

    //var mesh = document.getElementsByTagName('a-scene')[0].getObject3D('mesh');
    //if (mesh.getObjectByName('faceShape')) mesh.getObjectByName('faceShape').material.map = texture1
    //if (mesh.getObjectByName('Wolf3D_Outfit_Top')) mesh.getObjectByName('Wolf3D_Outfit_Top').material.map = texture1
    /*
        console.log(document.getElementsByTagName('a-scene')[0].getElementsByTagName('a-entity')[0])
        document.getElementsByTagName('a-scene')[0].getElementsByTagName('a-entity')[0].addEventListener('model-loaded', () => {
          var mesh = document.getElementsByTagName('a-scene')[0].getElementsByTagName('a-entity')[0].getObject3D('mesh');
    
          if (mesh.getObjectByName('faceShape')) mesh.getObjectByName('faceShape').material.map = texture1
          if (mesh.getObjectByName('Wolf3D_Outfit_Top')) mesh.getObjectByName('Wolf3D_Outfit_Top').material.map = texture1
    
          store.subscribe(function () {
            if (store.getState().ARMode) {
              var sound = new Howl({
                src: [voiceURL],
                volume: 1.0,
                onend: function () {
                  console.log('Finished!');
                }
              });
              setTimeout(() => {
                sound.play();
              }, 4000);
    
              document.getElementsByTagName('a-scene')[0].getElementsByTagName('a-entity')[0].setAttribute('animation-mixer', 'clip: BoyBot_clip; loop: once; clampWhenFinished: true');
              document.getElementsByTagName('a-scene')[0].getElementsByTagName('a-entity')[0].setAttribute('visible', true);
    
            }
          })
    
        })
    
        */
    // Cleanup
    return () => {
      const ascene = document.getElementsByTagName('a-scene')[0]
      ascene.parentNode.removeChild(ascene)
      html.className = origHtmlClass

    }
    // eslint-disable-next-line
  }, [])
  return null
}
const DISABLE_IMAGE_TARGETS = []
export { AFrameScene, DISABLE_IMAGE_TARGETS }
