const hotSpot2Reducer = (isLoaded = false, action) => {
  switch (action.type) {
    case "HOTSPOT2_ON":
      console.log("HOTSPOT2_ON");
      return true;
    case "HOTSPOT2_OFF":
      console.log("HOTSPOT2_OFF");
      return false;
    default:
      return isLoaded;
  }
};
export default hotSpot2Reducer;
