const turnOnLightReducer = (isLoaded = false, action) => {
  switch (action.type) {
    case "TURN_ON_LIGHT":
      console.log("TURN_ON_LIGHT");
      return true;
    case "TURN_OFF_LIGHT":
      console.log("TURN_OFF_LIGHT");
      return false;
    default:
      return isLoaded;
  }
};
export default turnOnLightReducer;
