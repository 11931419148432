import React from "react";

import { connect, useSelector } from "react-redux";
import { withTranslation } from "react-i18next";

import MainMenuButton from "./MainMenuButton";

function MainMenuRight(props) {

  let t = props.t;
  const experienceState = useSelector((state) => state.experienceState);

  React.useEffect(() => {
    console.log(t("Deck"),)
  }, [])
  return (
    <section className={`main-menu-right ${props.visible ? "active" : ""}`}>
      <div className="menu-block-content">
        <div className="menu-block-item">
          <MainMenuButton textProp={experienceState.experienceResponseData?.result.menu1} urlProp={experienceState.experienceResponseData?.result.URL1} />
          <MainMenuButton textProp={experienceState.experienceResponseData?.result.menu2} urlProp={experienceState.experienceResponseData?.result.URL2} />
          <MainMenuButton textProp={experienceState.experienceResponseData?.result.menu3} urlProp={experienceState.experienceResponseData?.result.URL3} />
          <MainMenuButton textProp={experienceState.experienceResponseData?.result.menu4} urlProp={experienceState.experienceResponseData?.result.URL4} />

          {/*<MainMenuButton textProp={t("WHO WE ARE")} audioProp={t("WHO WE ARE Audio")} urlProp={t("WHO WE ARE URL")} />
          <MainMenuButton textProp={t("MAGIC MIRROR")} audioProp={t("MAGIC MIRROR Audio")} urlProp={t("MAGIC MIRROR URL")} />
          <MainMenuButton textProp={t("AR IYP")} audioProp={t("AR IYP Audio")} urlProp={t("AR IYP URL")} />
          <MainMenuButton textProp={t("EXR CODES")} audioProp={t("EXR CODES Audio")} urlProp={t("EXR CODES URL")} />
          <MainMenuButton textProp={t("CONTACT US")} audioProp={t("CONTACT US Audio")} urlProp={t("CONTACT US URL")} /> */}
        </div>
      </div>
    </section>
  );

}

const mapStateToProps = (state) => {
  return {
    visible: state.settingsRight,
  };
};

export default withTranslation()(
  connect(mapStateToProps)(MainMenuRight)
);
