import * as React from 'react';
import { useSelector, useDispatch } from 'react-redux'
import { LETS_GO } from '../Redux/constants/actionTypes'
import Box from '@mui/material/Box';
import Zoom from '@mui/material/Zoom';


import IntroBgImage from '../img/CocaCola_bg.jpg';
import IntroLogo from '../img/CocaCola_intro_logo.png';
import IntroCheckBox from '../img/Heineken_intro_checkbox.png';
import IntroBottom from '../img/Heineken_intro_bottom.png';
import IntroCheckBoxChecked from '../img/Heineken_intro_checkbox_Checked.png';
import IntroPushHere from '../img/BBAR_Push_Here.png'

const flexGrowIntro = {
  flexGrowIntroLogo: 4, flexGrowCheckBox: 2, flexGrowBottom: 2,

}
const imagesArray = [
  IntroCheckBox, IntroCheckBoxChecked, IntroPushHere
]

export default function IntroPage() {
  //const count = useSelector((state) => state.counter.value)
  const { letsGoState, outroState, hotSpot1State, hotSpot2State, hotSpot3State } = useSelector((state) => state);
  const [onOff, setOnOff] = React.useState(true);
  const [clickCounter, setClickCounter] = React.useState(0);
  const dispatch = useDispatch()
  let handleClick = () => {

    dispatch({ type: "RUN_AR_MODE" });
    console.log('dispatch({ type: "RUN_AR_MODE" })')
    setClickCounter(clickCounter + 1)
  }
  return (
    (clickCounter == 1) ? null :
      <Box
        sx={{
          position: 'absolute',
          left: 0,
          top: 0,
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          /*
          backgroundImage: `url(${IntroBgImage})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          */
          backgroundColor: "transparent",
          zIndex: 2
        }}
      >
        <Box sx={{
          display: 'flex',
          flexGrow: flexGrowIntro.flexGrowIntroLogo,
          /*backgroundImage: `url(${IntroLogo})`,*/
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }}>
         
        </Box>

        <Box sx={{
          flexGrow: flexGrowIntro.flexGrowCheckBox,
          backgroundImage: `url(${imagesArray[2]})`,
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
        }} onClick={handleClick}>
        </Box>
        <Box sx={{
          flexGrow: flexGrowIntro.flexGrowBottom,
          backgroundColor: 'transparent',
        }}></Box>
      </Box>

  );
}