const hotSpot3Reducer = (isLoaded = false, action) => {
  switch (action.type) {
    case "HOTSPOT3_ON":
      console.log("HOTSPOT3_ON");
      return true;
    case "HOTSPOT3_OFF":
      console.log("HOTSPOT3_OFF");
      return false;
    default:
      return isLoaded;
  }
};
export default hotSpot3Reducer;
