import { combineReducers } from "redux";

import menuRightReducer from "./Redux/reducers/menuRightReducer";
import menuLeftReducer from "./Redux/reducers/menuLeftReducer";
import greetingAudioReducer from "./Redux/reducers/greetingAudioReducer";
import buttonAugieReducer from "./Redux/reducers/buttonAugieReducer";
import augieReducer from "./Redux/reducers/augieReducer";
import greetingAudioFinishedReducer from "./Redux/reducers/greetingAudioFinishedReducer";
import menuUrlReducer from "./Redux/reducers/menuUrlReducer";
import runARModeReducer from "./Redux/reducers/ARModeReducer";
import modelsLoaderReducer from "./Redux/reducers/modelsLoaderReducer";
import letsGoReducer from "./Redux/reducers/letsGoReducer";
import tapToPlaceReducer from "./Redux/reducers/tapToPlaceReducer";
import changecolorReducer from "./Redux/reducers/changecolor";
import outroReducer from "./Redux/reducers/outro";
import turnOnLightReducer from "./Redux/reducers/turnOnLight";
import restartReducer from "./Redux/reducers/restartReducer";
import hotSpot1Reducer from "./Redux/reducers/hotSpot1Reducer";
import hotSpot2Reducer from "./Redux/reducers/hotSpot2Reducer";
import hotSpot3Reducer from "./Redux/reducers/hotSpot3Reducer";
import customEditReducer from "./Redux/reducers/custom";
import experienceReducer from "./Redux/reducers/experience";

const rootReducer = combineReducers({

  settingsRight: menuRightReducer,
  settingsLeft: menuLeftReducer,
  greetingAudio: greetingAudioReducer,
  buttonAugie: buttonAugieReducer,
  modelAugie: augieReducer,
  greetingAudioFinishedEvent: greetingAudioFinishedReducer,
  menuUrl: menuUrlReducer,
  ARMode: runARModeReducer,
  modelsAreLoaded: modelsLoaderReducer,
  letsGoState: letsGoReducer,
  tapToPlaceState: tapToPlaceReducer,
  changecolorState: changecolorReducer,
  outroState: outroReducer,
  turnOnLightState: turnOnLightReducer,
  restartState: restartReducer,
  hotSpot1State: hotSpot1Reducer,
  hotSpot2State: hotSpot2Reducer,
  hotSpot3State: hotSpot3Reducer,
  customEditState: customEditReducer,
  experienceState: experienceReducer,
});

export default rootReducer;
